import './Contact.css';
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { HiIdentification } from "react-icons/hi2";
import { FaMessage } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

const variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
}

export function Contact({ darkMode }) {
    const [animateContact, setAnimateContact] = useState(false);
    const animateRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setAnimateContact(true);
                }
            });
        });
        observer.observe(animateRef.current);
    }, []);

    return (
        <div className='contact-wrapper'>
            <h1 className='title'>Pour me contacter</h1>
            <div className="contact-container" ref={animateRef}>
                <motion.div className="contact" initial="hidden" animate={animateContact ? 'visible' : "hidden"} variants={variants}>
                    <div className="leftContact">
                        <div className="firstcontact">
                            <div className="contactinfo">
                                <div className="icon">
                                    <div className="circle"><IoMdMail /></div>
                                </div>
                                <div className="textInfo">
                                    <h2>Email</h2>
                                    <p>tchitdjian.lucas@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="secondcontact">
                            <div className="contactinfo">
                                <div className="icon">
                                    <div className="circle"><FaPhoneAlt /></div>
                                </div>
                                <div className="textInfo">
                                    <h2>Numéro de téléphone</h2>
                                    <p>06 58 57 68 20</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rightContact">
                        <form action="https://formspree.io/f/mgegkzee" method='POST' className='flex-col'>
                            <div className="inputBox">
                                <label htmlFor="firstName"><HiIdentification /> Prénom *</label>
                                <input type="text" id="firstName" name="firstName" required="required" />
                            </div>
                            <div className="inputBox">
                                <label htmlFor="lastName"><HiIdentification /> Nom *</label>
                                <input type="text" id="lastName" name="lastName" required="required" />
                            </div>
                            <div className="inputBox">
                                <label htmlFor="email"><IoMdMail /> Email *</label>
                                <input type="email" id="email" name="email" required="required" />
                            </div>
                            <div className="inputBox">
                                <label htmlFor="phone"><FaPhoneAlt /> Numéro de téléphone</label>
                                <input type="text" id="phone" name="phone" />
                            </div>
                            <div className="inputBox textarea">
                                <label htmlFor="message"><FaMessage /> Message *</label>
                                <textarea id="message" name='message' required="required"></textarea>
                            </div>
                            <div className="inputBox submit">
                                <input type="submit" name="submit" value="Envoyer mon message" />
                            </div>
                        </form>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}