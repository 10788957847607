import './Skills.css';
import { Element } from 'react-scroll';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

const skills = [
    { name: 'HTML5', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/197px-HTML5_logo_and_wordmark.svg.png' },
    { name: 'CSS3', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/langfr-195px-CSS3_logo_and_wordmark.svg.png' },
    { name: 'Javascript', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/197px-Unofficial_JavaScript_logo_2.svg.png' },
    { name: 'ReactJS', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/React_Logo_SVG.svg/langfr-330px-React_Logo_SVG.svg.png' },
    { name: 'Firebase', image: 'https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg' },
    { name: 'Next JS', image: 'https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png' },
    { name: 'Angular', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/768px-Angular_full_color_logo.svg.png' },
    { name: 'MySQL', image: 'https://upload.wikimedia.org/wikipedia/fr/thumb/6/62/MySQL.svg/langfr-330px-MySQL.svg.png' },
    { name: 'Php', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/330px-PHP-logo.svg.png' },
    { name: 'Ruby on Rails', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Ruby_On_Rails_Logo.svg/180px-Ruby_On_Rails_Logo.svg.png' },
    { name: 'Laravel', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/330px-Laravel.svg.png' },
    { name: 'Symfony', image: 'images/icons8-symfony.svg' },
];

export function Skills() {

    const [animateSkills, setAnimateSkills] = useState(false);
    const skillsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("show");
                        setAnimateSkills(true);
                    }
                });
            }, { threshold: 0.1 }
        );

        // Capture the current value of skillsRef.current inside the effect
        const currentSkillsRef = skillsRef.current;
        if (currentSkillsRef) {
            observer.observe(currentSkillsRef);
        }

        // Use the captured value in the cleanup function
        return () => {
            if (currentSkillsRef) {
                observer.unobserve(currentSkillsRef);
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once


    const skillVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return (
        <Element name="skills" className="skills-element">
            <div className='skills-wrapper'>
                <h1 className='title'>Compétences techniques</h1>
                <div ref={skillsRef} className='skills-experience'>
                    <motion.div className="skills">
                        {skills.map((skill, index) => (
                            <motion.div
                                key={index}
                                initial="hidden"
                                animate={animateSkills ? "visible" : "hidden"}
                                variants={skillVariants}
                                transition={{ duration: 0.8, delay: index * 0.5 }}
                                className="skill">
                                <div className="image">
                                    <div className="html"><img id={skill.name} src={skill.image} alt={skill.name} /></div>
                                </div>
                                <p>{skill.name}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </Element>
    )
}