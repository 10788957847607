import './Hero.css';
import '../App.css';
import React from 'react';
import Button from '@mui/material/Button';
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { motion } from "framer-motion";

export function Hero() {

    const links = [
        { name: 'Linkedin', icon: <FaLinkedin />, href: 'https://www.linkedin.com/in/lucas-tchitdjian/' },
        { name: 'Github', icon: <FaGithub />, href: 'https://github.com/LucasTchitdjian' },
        { name: 'Mail', icon: <IoMdMail />, href: 'mailto:tchitdjian.lucas@gmail.com' }
    ];

    return (
        <div className="hero-wrapper">
            <motion.div className="hero" initial="initial" animate="animate">
                <div className="left">
                    <h1>Étudiant Ingénieur - Informatique et Cybersécurité (Recherche d'alternance)</h1>
                    <h2>Je protège les infrastructures numériques et garantis la confidentialité des utilisateurs.</h2>
                    <div className="links">
                       <motion.ul initial="closed" animate="open">
                            {links.map((link, index) => (
                                <motion.li key={index}>
                                    <Button href={link.href} variant="text" className='links-btn'>
                                        {link.name} {link.icon}
                                    </Button>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </div>
                </div>
                <div className="right">
                    <div className="profil-img-container">
                        <img fetchPriority='high' src='images/imageprofil.webp' alt='Lucas Tchitdjian' />
                    </div>
                </div>
            </motion.div>
        </div>
    );
}