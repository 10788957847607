import './App.css';
import Header from './components/Header';
import { Hero } from './components/Hero';
import { About } from './components/About';
import { Projects } from './components/Projects';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { Skills } from './components/Skills';
import { DownloadCv } from './components/DownloadCv';
import { Experiences } from './components/Experiences';
// import { Formations } from './components/Formations';
// import { Faq } from './components/Faq';
import React, { useState, useEffect, Suspense } from 'react';

const Formations = React.lazy(() => import('./components/Formations'));
const Faq = React.lazy(() => import('./components/Faq'));

function App() {

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.remove('dark-mode');
    } else {
      document.body.classList.add('dark-mode');
    }
  }, [darkMode]);

  return (
    <div className="App">
      <Header darkMode={darkMode} setDarkMode={setDarkMode}/>
      <Hero />
      <About darkMode={darkMode} setDarkMode={setDarkMode}/>
      <Skills darkMode={darkMode}/>
      <Experiences darkMode={darkMode} setDarkMode={setDarkMode}/>
      <Suspense fallback={<div>Loading...</div>}>
        <Formations darkMode={darkMode} setDarkMode={setDarkMode}/>
        <Faq darkMode={darkMode} setDarkMode={setDarkMode} />
      </Suspense>
      <Projects darkMode={darkMode} setDarkMode={setDarkMode}/>
      <DownloadCv />
      <Contact darkMode={darkMode}/>
      <Footer darkMode={darkMode} setDarkMode={setDarkMode}/>
    </div>
  );
}

export default App;
