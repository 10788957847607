import './Experiences.css';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

export function Experiences({ darkMode }) {

    const [animateExperiences, setAnimateExperiences] = useState(false);
    const experiencesRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateExperiences(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentRef = experiencesRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const experiencesVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return (
        <div className="experienceswrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path className='svg-skills' fill={darkMode ? '#eee' : '#1d1d1d'} fillOpacity="1" d="M0,0L120,53.3C240,107,480,213,720,218.7C960,224,1200,128,1320,80L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            <h1>Expériences professionnelles</h1>
            <div className="experiencescontainer" ref={experiencesRef}>
                <motion.div className="experience" initial="hidden"
                    animate={animateExperiences ? "visible" : "hidden"}
                    variants={experiencesVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <h2>Développeur WEB chez Superlative Apps</h2>
                    <motion.div className="postInfo">
                        <div className="years">Janvier 2023 - Décembre 2023</div>
                        <div className="location">Marseille 13ème</div>
                        <div className="post-title">
                            <p><strong>Contribution au développement front-end</strong> de l'extension Chooz'it en JavaScript, en implémentant des fonctionnalités de récupération et d'intégration des données produits.</p>
                            <p><strong>Création d'une minigalerie d'images</strong> adaptative grâce à CSS Grid, capable d'afficher jusqu'à 5 photos de manière optimale sur tous les supports, en ajustant automatiquement la disposition en fonction de la taille de l'écran.</p>
                            <p><strong>Développement d'une fonctionnalité d'évaluation</strong> de la qualité des annonces en React JS, intégrée à une extension de navigateur, pour optimiser l'expérience utilisateur.</p>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div className="experience" initial="hidden"
                    animate={animateExperiences ? "visible" : "hidden"}
                    variants={experiencesVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <h2>Assistant de communication chez la courtoise ressourcerie</h2>
                    <div className="postInfo">
                        <div className="years">Avril 2022 - Octobre 2022</div>
                        <div className="location">Saint maximin la sainte baume</div>
                        <div className="post-title">
                            <p><strong>Développement d'une interface "Carrières"</strong> où les visiteurs peuvent consulter les descriptions détaillées des opportunités professionnelles et bénévoles disponibles au sein de l'association.</p>
                            <p><strong>Conception d'une section "Presse"</strong> dédiée à l'agrégation et à la présentation des diverses couvertures médiatiques et apparitions publiques de l'association, offrant un accès centralisé aux interviews, articles, et reportages.</p>
                            <p><strong>Intégration d'un module d'abonnement</strong> à la lettre d'information dans le pied de page du site web, permettant aux utilisateurs de s'inscrire facilement pour recevoir les dernières nouvelles et mises à jour de l'association.</p>
                        </div>
                    </div>
                </motion.div>
                <motion.div className="experience" initial="hidden"
                    animate={animateExperiences ? "visible" : "hidden"}
                    variants={experiencesVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <h2>Développeur décisionnaire</h2>
                    <div className="postInfo">
                        <div className="years">Septembre 2019 - Septembre 2020</div>
                        <div className="location">Aix-en-provence la duranne</div>
                        <div className="post-title">
                            <p><strong>Participation au projet AMP</strong> (Aéroport Marseille Provence) en tant qu'intégrateur de données, après une formation sur la solution Talend.</p>
                            <p><strong>Mise en place de flux d'intégration de données complexes</strong> pour répondre aux besoins spécifiques de l'aéroport, améliorant ainsi l'efficacité opérationnelle et la prise de décision.</p>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}