import './Footer.css';
import '../App.css';
import { IoMdMail } from "react-icons/io";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export function Footer({ darkMode }) {
  return (
    <footer>
      <div className="footerContainer">
        <div className="topsection">
          <div className="logo">
            <img src="images/logo.webp" alt="logo" />
            <p>Lucas Tchitdjian</p>
          </div>
        </div>
        <div className="bottomsection">
          <div className="copyright"><h3>Copyright © 2024 - <span>Tous droits réservés </span></h3></div>
          <div className="links">
            <a href="mailto:tchitdjian.lucas@gmail.com"><IoMdMail /></a>
            <a href="https://github.com/LucasTchitdjian"><FaGithub /></a>
            <a href="https://www.linkedin.com/in/lucas-tchitdjian/"><FaLinkedin /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}