import './Projects.css';
import { FaArrowRight } from "react-icons/fa";
import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const projectsVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};

export function Projects({ darkMode }) {

    const [animateProjects, setAnimateProjects] = useState(false);
    const projectsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateProjects(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentRef = projectsRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div className='projects-wrapper' ref={projectsRef}>
            <div className="projects">
                <h1>Mes derniers projets</h1>
                <motion.div className="cards">
                    <motion.div className="card" initial="hidden"
                        animate={animateProjects ? "visible" : "hidden"}
                        variants={projectsVariants}
                        transition={{ duration: 1, delay: 0.5 }}>
                        <div className="top">
                            <a href="https://spacetourismlucas.netlify.app">
                                <h2>Reactonauts</h2>
                            </a>
                            <a href="https://spacetourismlucas.netlify.app" className='svg-link'>
                                <FaArrowRight /> <span className="sr-only">Voir le projet Reactonauts</span>
                            </a>
                        </div>
                        <div className="img">
                            <img src="images/CaptureSpaceTourism.webp" alt="Capture d'écran du projet Reactonauts" />
                        </div>
                        <p>Application web multi pages présentant le futur du tourisme spatial. Réalisé à l’aide du framework REACT JS.</p>
                    </motion.div>
                    <motion.div className="card" initial="hidden"
                        animate={animateProjects ? "visible" : "hidden"}
                        variants={projectsVariants}
                        transition={{ duration: 1, delay: 1 }}>
                        <div className="top">
                            <a href="https://restcountriesapi-lucas.netlify.app">
                                <h2>RESTful Nations</h2>
                            </a>
                            <a href="https://restcountriesapi-lucas.netlify.app" className='svg-link'>
                                <FaArrowRight /> <span className="sr-only">Voir le projet RESTful Nations</span>
                            </a>
                        </div>
                        <div className="img">
                            <img src="images/ProjetAPIRestCountries.webp" alt="Capture d'écran du projet RESTful Nations" />
                        </div>
                        <p>Application web utilisant une api de type REST qui permet de rechercher un pays en effectuant un tri par continent. Réalisé aussi à l’aide de REACT JS.</p>
                    </motion.div>
                    <motion.div className="card" initial="hidden"
                        animate={animateProjects ? "visible" : "hidden"}
                        variants={projectsVariants}
                        transition={{ duration: 1, delay: 1.5 }}>
                        <div className="top">
                            <a href="https://mooviestracker.firebaseapp.com/now-playing">
                                <h2>What to watch</h2>
                            </a>
                            <a href="https://mooviestracker.firebaseapp.com/now-playing" className='svg-link'>
                                <FaArrowRight /> <span className="sr-only">Voir le projet What to watch</span>
                            </a>
                        </div>
                        <div className="img">
                            <img loading='lazy' src="images/CaptureMooviesTracker.webp" alt="Capture d'écran du projet What to watch" />
                        </div>
                        <p>Cette plateforme permettra à chaque utilisateur de créer un profil unique afin de suivre et de gérer sa liste de contenus visionnés.</p>
                    </motion.div>
                </motion.div>
            </div>
            <motion.div ref={projectsRef} className="projects-swipper" initial="hidden"
                animate={animateProjects ? "visible" : "hidden"}
                variants={projectsVariants}
                transition={{ duration: 1, delay: 0.5 }}>
                <swiper-container style={darkMode ? { "--swiper-navigation-color": "#000", "--swiper-pagination-color": "#000" } : { "--swiper-navigation-color": "#fff", "--swiper-pagination-color": "#fff" }} space-between="10" slides-per-view="1" pagination="true">
                    <swiper-slide>
                        <div className="cards-swipper">
                            <motion.div className="card-swipper">
                            <a href="https://spacetourismlucas.netlify.app">
                                <div className="top">
                                    <h2>Reactonauts</h2>
                                </div>
                                <div className="img">
                                    <img fetchPriority='low' src="images/CaptureSpaceTourism.webp" alt="Capture d'écran du projet voyage spatial" />
                                </div>
                                </a>
                            </motion.div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="cards-swipper">
                            <motion.div className="card-swipper">
                            <a href="https://restcountriesapi-lucas.netlify.app">
                                <div className="top">
                                        <h2>Projet API REST de pays</h2>
                                </div>
                                <div className="img">
                                    <img fetchPriority='low' src="images/ProjetAPIRestCountries.webp" alt="Capture d'écran du projet API REST de pays" />
                                </div>
                                </a>
                            </motion.div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="cards-swipper">
                            <motion.div className="card-swipper">
                            <a href="https://mooviestracker.firebaseapp.com">
                                <div className="top">
                                    <h2>Application de suivi films et séries</h2>
                                </div>
                                <div className="img">
                                    <img fetchPriority='low' src="images/CaptureMooviesTracker.webp" alt="Capture d'écran du projet Application de suivi films et séries" />
                                </div>
                            </a>
                            </motion.div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </motion.div>
        </div>
    );
}